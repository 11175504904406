import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import heroBg from "../images/limpar-section-background-3-q80.webp"
import './404.css'

const NotFoundPage = () => (
  <Layout headerColorMode="dark">
    <div
      style={{
        backgroundImage: `url("${heroBg}")`
      }}
      className="align-items-center container-fluid d-flex flex-column justify-content-center lp-dark-hero vh-100 lp-404">
      <div className="row">
        <div className="col d-flex flex-column justify-content-center align-items-center">
          <h1 style={{
            fontSize: "6rem"
          }}>404</h1>
          <p
            style={{
              fontSize: "3rem"
            }}
          >Η σελίδα δεν βρέθηκε</p>
          <p
          style={{
            textAlign: "center"
          }}
          >Τα νερά εδώ είναι θολά...</p>
          <Link  to="/" className="btn btn-light lp-styled-btn">Επιστροφή στην αρχική</Link>
        </div>
      </div>
    </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage
